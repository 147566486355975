import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import * as ReactMarkdown from 'react-markdown'

import * as C from '../components'
import * as S from '../styles'

import pressBanner from '../assets/press-banner.jpg'

const Item = styled.div`
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  ${S.Button} {
    width: 100%;
    margin-top: 15px;
  }
`

const ItemPub = styled(S.Text)`
  color: #0a2721;
  font-weight: 600;
  margin: 10px 0;
`

export const query = graphql`
  {
    allContentfulPressItem {
      edges {
        node {
          id
          title
          heading
          copy {
            id
            copy
          }
          url
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`

const Press: React.SFC<any> = ({ data }) => {
  const items = data.allContentfulPressItem.edges

  return (
    <C.App>
      <S.Section
        className="intro product-intro"
        style={{ background: `url(${pressBanner}) center / cover no-repeat` }}>
        <S.Wrapper className="small">
          <S.Heading className="large white">Press</S.Heading>
        </S.Wrapper>
      </S.Section>
      <S.Section className="sub">
        <S.Wrapper>
          <S.Text className="center large" style={{ marginBottom: 30 }}>
            For media inquiries, contact <strong><a href="mailto:press@lieuditwinery.com">press@lieuditwinery.com</a></strong>
          </S.Text>
          <S.Grid style={{ justifyContent: 'flex-start' }}>
            {items.map(i => {
              const { id, title, heading, copy, url, pdf } = i.node
              const urlToShow = url ? url : pdf.file.url
              return (
                <S.GridItem
                  key={id}
                  width="31%"
                  breakpoints={{
                    768: '100%',
                  }}
                  style={{ marginLeft: '2%', marginBottom: '2%' }}>
                  <Item>
                    <S.Heading className="smedium">{title}</S.Heading>
                    <ItemPub className="medium">{heading}</ItemPub>
                    <S.Text as="div" className="medium">
                      <ReactMarkdown source={copy.copy} />
                    </S.Text>
                    <S.Button as="a" href={urlToShow} target="_blank">
                      Read More
                    </S.Button>
                  </Item>
                </S.GridItem>
              )
            })}
          </S.Grid>
        </S.Wrapper>
      </S.Section>
      <C.JoinCommunity />
    </C.App>
  )
}

export default Press
